import styled from 'styled-components'

const DescriptionTextareaContainer = styled.div`
  margin-bottom: 16px;
  
  &:last-child {
    margin-bottom: 0;
  }
`

const DescriptionLabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`

const DescriptionLabel = styled.label`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: var(--color-text-primary);
`

export { DescriptionTextareaContainer, DescriptionLabelContainer, DescriptionLabel }
