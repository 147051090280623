import { store } from 'index'
import { SupportUserType } from 'models/SupportUserType'

export const SAVE_CLOSED_SUPPORT_USERS = 'SAVE_CLOSED_SUPPORT_USERS'
export const SAVE_MORE_CLOSED_SUPPORT_USERS = 'SAVE_MORE_CLOSED_SUPPORT_USERS'
export const UPDATE_SUPPORT_USER_LIST = 'UPDATE_SUPPORT_USER_LIST'
export const UPDATE_USER_UNREAD_MESSAGE = 'UPDATE_USER_UNREAD_MESSAGE'
export const SAVE_NEW_SUPPORT_USERS = 'SAVE_NEW_SUPPORT_USERS'
export const SAVE_EXPIRED_SUPPORT_USERS = 'SAVE_EXPIRED_SUPPORT_USERS'
export const SAVE_ASSIGNED_SUPPORT_USERS = 'SAVE_ASSIGNED_SUPPORT_USERS'
export const SAVE_ASSIGNED_TO_SUPPORT_USERS = 'SAVE_ASSIGNED_TO_SUPPORT_USERS'
export const SAVE_ALL_SUPPORT_USERS = 'SAVE_ALL_SUPPORT_USERS'
export const SAVE_MORE_ALL_SUPPORT_USERS = 'SAVE_MORE_ALL_SUPPORT_USERS'
export const SAVE_MORE_EXPIRED_SUPPORT_USERS = 'SAVE_MORE_EXPIRED_SUPPORT_USERS'
export const SAVE_MORE_ASSIGNED_TO_SUPPORT_USERS = 'SAVE_MORE_ASSIGNED_TO_SUPPORT_USERS'
export const SAVE_MORE_NEW_SUPPORT_USERS = 'SAVE_MORE_NEW_SUPPORT_USERS'
export const SAVE_MORE_ASSIGNED_SUPPORT_USERS = 'SAVE_MORE_ASSIGNED_SUPPORT_USERS'

export const updateSupportUserList = (user: SupportUserType, adminUserId: number): void => {
  store.dispatch({
    type: UPDATE_SUPPORT_USER_LIST,
    payload: {
      user,
      adminUserId,
    },
  })
}

export const updateUserUnreadMessages = (user: SupportUserType): void => {
  store.dispatch({
    type: UPDATE_USER_UNREAD_MESSAGE,
    payload: {
      user,
    },
  })
}

export const saveNewSupportUsers = (supportUsers: SupportUserType[] = []): void => {
  store.dispatch({
    type: SAVE_NEW_SUPPORT_USERS,
    payload: {
      supportUsers,
    },
  })
}

export const saveExpiredSupportUsers = (supportUsers: SupportUserType[] = []): void => {
  store.dispatch({
    type: SAVE_EXPIRED_SUPPORT_USERS,
    payload: {
      supportUsers,
    },
  })
}

export const saveAssignedSupportUsers = (supportUsers: SupportUserType[] = [], adminUserId: number): void => {
  store.dispatch({
    type: SAVE_ASSIGNED_SUPPORT_USERS,
    payload: {
      supportUsers,
      adminUserId,
    },
  })
}

export const saveAssignedToSupportUsers = (supportUsers: SupportUserType[] = []): void => {
  store.dispatch({
    type: SAVE_ASSIGNED_TO_SUPPORT_USERS,
    payload: {
      supportUsers,
    },
  })
}

export const saveAllSupportUsers = (supportUsers: SupportUserType[] = []): void => {
  store.dispatch({
    type: SAVE_ALL_SUPPORT_USERS,
    payload: {
      supportUsers,
    },
  })
}

export const saveMoreAllSupportUsers = (supportUsers: SupportUserType[] = []): void => {
  store.dispatch({
    type: SAVE_MORE_ALL_SUPPORT_USERS,
    payload: {
      supportUsers,
    },
  })
}

export const saveMoreSupportExpiredUsers = (supportUsers: SupportUserType[] = []): void => {
  store.dispatch({
    type: SAVE_MORE_EXPIRED_SUPPORT_USERS,
    payload: {
      supportUsers,
    },
  })
}

export const saveMoreSupportAssignedToMeUsers = (supportUsers: SupportUserType[] = []): void => {
  store.dispatch({
    type: SAVE_MORE_ASSIGNED_TO_SUPPORT_USERS,
    payload: {
      supportUsers,
    },
  })
}

export const saveMoreSupportNewUsers = (supportUsers: SupportUserType[] = []): void => {
  store.dispatch({
    type: SAVE_MORE_NEW_SUPPORT_USERS,
    payload: {
      supportUsers,
    },
  })
}

export const saveMoreAssignedUsers = (supportUsers: SupportUserType[] = [], adminUserId: number): void => {
  store.dispatch({
    type: SAVE_MORE_ASSIGNED_SUPPORT_USERS,
    payload: {
      supportUsers,
      adminUserId,
    },
  })
}
