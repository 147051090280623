import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Input from '../../../../../uiKit/inputs/Input'
import SubmitButton from '../../../../../uiKit/buttons/SubmitButton'
import { TrashIcon } from '../../../../../uiKit/icons/TrashIcon'
import AttributeSelect from '../../../../../uiKit/AttributeSelect/AttributeSelect'
import Table from '../../../../../uiKit/table/Table'
import TableRow from '../../../../../uiKit/table/TableRow'
import TableCell from '../../../../../uiKit/table/TableCell'
import Switch from '../../../../../uiKit/Switch'
import InfoAlert from '../../../../../uiKit/alerts/InfoAlert'
import { getAttributes } from '../../../../../tabs/settings/api/attributes.js'
import { CHATBOT, DEFAULT } from '../../../../../constants/attributeTypes'
import { alertSuccess, alertWarning, alertError } from '../../../../../api'
import {
  ZAPIER_CONFIGS_PLACEHOLDER,
  fetchZapierConfigs,
  sendTestWebhookNotification,
  updateZapierConfigs,
} from './zapierApi'

import * as S from './ZapierIntegration.style'
import { ZapierConfigs } from 'models/ZapierConfigs'

const tableTitles = [
  {
    id: 'name',
    title: 'Name',
    maxWidth: 480,
  },
  {
    id: 'required',
    title: 'Required',
    maxWidth: 140,
    tooltipText:
      "A request will only be sent once all of the 'Required' attributes have been " +
      'successfully collected from the user',
  },
  {
    // Trash icon column
    id: 'bin',
    title: '',
    maxWidth: 30,
  },
]

const getTableCellStyle = columnId => ({
  maxWidth: tableTitles.find(title => title.id === columnId).maxWidth,
  height: '24px',
})

const tableRowStyle = {
  height: '24px',
  minHeight: '24px',
  padding: 0,
  margin: '8px 0',
  backgroundColor: 'white',
}

const guideLink = 'https://www.notion.so/knowledgebasebotscrew/Zapier-Integration-2246061e9c3344e48a784f7e9df3a071'

interface ZapierProps {
  activeBot: any
  attributes: any[]
}

const ZapierIntegration: React.FC<ZapierProps> = ({ activeBot, attributes }) => {
  const [zapierConfigs, setZapierConfigs] = useState(ZAPIER_CONFIGS_PLACEHOLDER)
  const [webhookUrlInput, setWebhookUrlInput] = useState(null)
  const [showAttributesChangedAlert, setShowAttributesChangedAlert] = useState(false)
  const [isWebhookUrlValid, setIsWebhookUrlValid] = useState(true)
  const isNewAttributeRequired = true

  useEffect(() => {
    getAttributes(activeBot.id, null)
  }, [])

  useEffect(() => {
    setIsWebhookUrlValid(!webhookUrlInput || validateWebhookUrl(webhookUrlInput))
  }, [webhookUrlInput])

  useEffect(() => {
    if (attributes && attributes.length) {
      fetchZapierConfigs(activeBot.id).then(configs => {
        setZapierConfigs({ ...configs, attributes: addNamesToAttributes(configs.attributes) })
        setWebhookUrlInput(configs.webhookUrl)
      })
    }
  }, [activeBot, attributes])

  const addNamesToAttributes = attributes => attributes.map(attribute => addAttributeName(attribute))

  const addAttributeName = attribute => ({ ...attribute, name: getAttributeName(attribute.attributeId) })

  const getAttributeName = attributeId => attributes.find(attribute => attribute.id === attributeId).name

  const updateConfigs = async newZapierConfigs => {
    const configs = await updateZapierConfigs(activeBot.id, newZapierConfigs)
    configs.attributes = addNamesToAttributes(configs.attributes)
    setZapierConfigs(configs)
    return configs
  }

  const handleTestRequestButtonClick = () => {
    updateAndSendTestRequest()
      .then(json => {
        if (json.error) {
          alertError('Test request failed. Please verify your webhook URL and ensure your Zap is active.')
        } else if (!zapierConfigs.isEnabled) {
          alertSuccess(
            'Test request has been sent. ' +
              'Switch on "Enable integration" and follow the Zapier integration guide for the next steps.',
          )
        } else {
          alertSuccess('Test request has been sent. Follow the Zapier integration guide for the next steps.')
        }
      })
      .catch(() =>
        alertError('Test request failed. Please check your webhook URL for validity and ensure your Zap is active.'),
      )
  }

  const updateAndSendTestRequest = async () => {
    // Update webhookUrl prior to sending test request
    await updateConfigs({ ...zapierConfigs, attributes: [...zapierConfigs.attributes], webhookUrl: webhookUrlInput })
    return await sendTestWebhookNotification(activeBot.id)
  }

  const updateWebhookUrl = newWebhookUrl => {
    const newZapierConfigs = {
      ...zapierConfigs,
      attributes: [...zapierConfigs.attributes],
      webhookUrl: newWebhookUrl,
    }
    updateConfigs(newZapierConfigs)
  }

  const validateWebhookUrl = webhookUrl =>
    /^$|^https:\/\/hooks\.zapier\.com\/hooks\/catch\/[0-9]+\/[a-zA-Z0-9]+\/?$/.test(webhookUrl)

  const handleWebhookUrlBlur = () => {
    if (isWebhookUrlValid) {
      updateWebhookUrl(webhookUrlInput)
    }
  }

  const handleIsEnabledChange = () => {
    const newZapierConfigs = { ...zapierConfigs, isEnabled: !zapierConfigs.isEnabled }
    const successMessage = newZapierConfigs.isEnabled ? 'Zapier integration enabled.' : 'Zapier integration disabled.'
    updateConfigs(newZapierConfigs).then(() => alertSuccess(successMessage))
  }

  const handleNewAttributeSelect = newAttribute => {
    const newAttributeId = newAttribute.value
    const attributeAlreadyAdded = zapierConfigs.attributes.some(
      zapierAttribute => zapierAttribute.attributeId === newAttributeId,
    )
    if (attributeAlreadyAdded) {
      alertWarning('Attribute has already been added to Zapier integration.')
      return
    }
    addAttributeToZapierConfigs(newAttributeId)
  }

  const addAttributeToZapierConfigs = attributeId => {
    const newZapierAttribute = {
      attributeId: attributeId,
      isRequired: isNewAttributeRequired,
    }
    const newZapierConfigs = { ...zapierConfigs, attributes: [...zapierConfigs.attributes] }
    newZapierConfigs.attributes.push(newZapierAttribute)
    setShowAttributesChangedAlert(true)
    updateConfigs(newZapierConfigs).then(() =>
      alertSuccess('Attribute saved. Press "Test request" to add your attributes to Zapier.'),
    )
  }

  const handleIsRequiredChange = attribute => {
    return () => {
      const newZapierConfigs: ZapierConfigs = { ...zapierConfigs, attributes: [...zapierConfigs.attributes] }
      const attributeToUpdate = newZapierConfigs.attributes.find(a => a.id === attribute.id)
      attributeToUpdate.isRequired = !attribute.isRequired
      setZapierConfigs(newZapierConfigs)
      setShowAttributesChangedAlert(true)
      updateConfigs(newZapierConfigs).then(() => alertSuccess('Zapier attribute updated.'))
    }
  }

  const handleRemoveAttribute = attribute => {
    return () => {
      const newZapierConfigs: ZapierConfigs = { ...zapierConfigs, attributes: [...zapierConfigs.attributes] }
      newZapierConfigs.attributes = newZapierConfigs.attributes.filter(a => a.id !== attribute.id)
      setZapierConfigs(newZapierConfigs)
      setShowAttributesChangedAlert(true)
      updateConfigs(newZapierConfigs).then(() => alertSuccess('Zapier integration attribute removed.'))
    }
  }

  const isTestButtonEnabled = () => webhookUrlInput && isWebhookUrlValid && zapierConfigs.attributes.length > 0

  return (
    <div style={{ fontSize: '14px' }}>
      <strong>Status</strong>
      <Switch
        style={{ marginLeft: '-12px' }}
        label="Enable integration"
        checked={zapierConfigs.isEnabled}
        onChange={handleIsEnabledChange}
      />
      <p style={{ fontWeight: 'bold', marginTop: '10px', marginBottom: '10px' }}>Webhook URL</p>
      <div className="d-flex gap-2">
        <Input
          value={webhookUrlInput || ''}
          onChange={e => setWebhookUrlInput(e.target.value)}
          onBlur={handleWebhookUrlBlur}
          placeholder="Enter here"
          style={{ borderRadius: '5px', padding: '10px 15px' }}
          error={!isWebhookUrlValid}
        />
        <SubmitButton
          onClick={handleTestRequestButtonClick}
          title="Test request"
          type="primary_with_border"
          size="sm"
          tooltip={
            isTestButtonEnabled() ? (
              ''
            ) : (
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  padding: '10px',
                  fontSize: '13px',
                }}>
                Enter Webhook URL and add attributes before sending test request.
              </div>
            )
          }
          className="w-100 align-stretch"
          disabled={!isTestButtonEnabled()}
          styles={{ borderRadius: '5px', padding: '10px 15px', maxWidth: '120px' }}
        />
      </div>
      {!isWebhookUrlValid && (
        <S.ErrorDescription>
          Enter a valid Webhook URL. For example: https://hooks.zapier.com/hooks/catch/123456/abc123
        </S.ErrorDescription>
      )}
      <S.Description>
        Guide to configure Zapier integration may be found{' '}
        <a href={guideLink} target="_blank" rel="noreferrer">
          here
        </a>
      </S.Description>
      <S.Container>
        <strong>Attributes</strong>
        <S.Description>
          Add attribute(s) you want to be sent with the webhook for a more personalized and efficient automation process
        </S.Description>
        {showAttributesChangedAlert && (
          <S.AlertWrap>
            <InfoAlert
              title={
                <p style={{ marginBottom: 0, lineHeight: 1.8 }}>
                  Your attribute list is updated, but it is <strong>not synced with your Zap</strong> yet. To sync it,
                  use the test request.
                  <br />
                  See{' '}
                  <a href={guideLink} target="_blank" rel="noreferrer">
                    this guide
                  </a>{' '}
                  for more information.
                </p>
              }
              closeable={true}
              onClose={() => setShowAttributesChangedAlert(false)}
            />
          </S.AlertWrap>
        )}
        <Table titles={tableTitles} headerStyles={{ padding: '5px 0 10px' }}>
          <TableRow style={{ ...tableRowStyle, marginTop: '20px', marginBottom: '20px' }}>
            <TableCell style={getTableCellStyle('name')}>
              <div className="pe-3 w-100">
                <AttributeSelect
                  styles={{ zIndex: 10 }}
                  focus={true}
                  onChange={handleNewAttributeSelect}
                  displayOptions={[DEFAULT, CHATBOT]}
                  isClearable={false}
                  placeholder={'Select attribute name'}
                  onTopDefault={true}
                />
              </div>
            </TableCell>
            <TableCell style={getTableCellStyle('required')}></TableCell>
            <TableCell style={getTableCellStyle('bin')}></TableCell>
          </TableRow>
          {zapierConfigs.attributes.length > 0 ? (
            zapierConfigs.attributes.map(attribute => (
              <TableRow style={tableRowStyle} key={attribute.id}>
                <TableCell style={getTableCellStyle('name')}>
                  <S.Attribute>{attribute.name}</S.Attribute>
                </TableCell>
                <TableCell style={getTableCellStyle('required')}>
                  <Switch checked={attribute.isRequired} onChange={handleIsRequiredChange(attribute)} />
                </TableCell>
                <TableCell style={getTableCellStyle('bin')}>
                  <S.TrashIconWrap onClick={handleRemoveAttribute(attribute)}>
                    <TrashIcon />
                  </S.TrashIconWrap>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '44px 0px 0px 0px',
              }}>
              <S.Description>No attributes added</S.Description>
            </div>
          )}
        </Table>
      </S.Container>
      <br />
    </div>
  )
}

const mapStateToProps = (state: { activeBot: any; attributes: any[] }) => ({
  attributes: state.attributes,
  activeBot: state.activeBot,
})

export default connect(mapStateToProps)(ZapierIntegration)
