import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import OpenRequests from '../OpenRequests'

import { styles } from './styles'

const DialogsContainer = props => {
  const { classes } = props

  return (
    <div className={classes.container}>
      <OpenRequests />
    </div>
  )
}

DialogsContainer.propTypes = {
  classes: PropTypes.object,
}

export default withRouter(withStyles(styles)(DialogsContainer))
