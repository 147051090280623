import styled from 'styled-components'

const Container = styled.div`
  margin-top: 16px;
`

const Attribute = styled.div`
  background-color: #5a98f7;
  color: var(--color-white);
  border-radius: 50px;
  padding: 1px 8px;
  font-size: 14;
  height: 24;
  width: fit-content;
  margin: 0;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const Description = styled.p`
  font-size: 12px;
  color: $raven-grey;
  font-family: Lato, sans-serif;
  margin-top: 8px;
  margin-bottom: 24px;
`

const ErrorDescription = styled(Description)`
  color: red;
`

const TrashIconWrap = styled.span`
  cursor: pointer;
`

const AlertWrap = styled.div`
  margin: 5px 0;
`

export { Container, Attribute, Description, ErrorDescription, TrashIconWrap, AlertWrap }
