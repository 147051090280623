import { debounce } from 'lodash'

import { alertError, createJsonHeaders, logoutOnRequestOrResponseJson } from '../../../../api'
import { BASE_URL, WIDGET_BASE_URL, SERVER_URL } from 'configs'

const WIDGET_SCRIPT_ID = 'chatbot-initials-script'
const WIDGET_IFRAME_ID = 'botscrew-widget'

export const initCopilotWidgetScript = (adminUser, activeBot) => {
  const script = document.createElement('script')
  script.setAttribute('id', WIDGET_SCRIPT_ID)
  fetchCopilotPublicIdentifier().then(copilotPublicIdentifier => {
    if (copilotPublicIdentifier) {
      setWidgetScriptAttributes(script, copilotPublicIdentifier, adminUser, activeBot)
    }
  })
  document.body.appendChild(script)
}

const fetchCopilotPublicIdentifier = async () => {
  try {
    const response = await fetch(`${BASE_URL}/copilot/publicIdentifier`, {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'GET',
    })
    return logoutOnRequestOrResponseJson(response).then(json => json.data?.publicIdentifier)
  } catch (error) {
    alertError('Failed to fetch Copilot data.')
  }
}

const setWidgetScriptAttributes = (script, copilotBotPublicId, adminUser, activeBot) => {
  script.setAttribute('type', 'text/javascript')
  script.setAttribute('data-server-url', `${SERVER_URL}/api`)
  script.setAttribute('data-bot-id', copilotBotPublicId)
  script.setAttribute('data-icon-url', '/images/platform/copilot-icon.svg')
  script.setAttribute('src', `${WIDGET_BASE_URL}/script-chatbot.js`)
  const adminData = JSON.stringify({
    userEmail: adminUser.email,
    userName: adminUser.name,
    botId: activeBot.id + '',
  })
  script.setAttribute('data-widget-metadata', JSON.stringify(adminData))
}

export const removeCopilotWidget = () => {
  const widgetIframe = document.getElementById(WIDGET_IFRAME_ID)
  if (isIframe(widgetIframe)) {
    widgetIframe.contentWindow.postMessage({ type: 'clear-chat-history' }, WIDGET_BASE_URL)
    widgetIframe.contentWindow.postMessage({ type: 'remove-event-listeners' }, WIDGET_BASE_URL)
  }

  removeCopilotWidgetElements()
}

const isIframe = (input: HTMLElement | null): input is HTMLIFrameElement => input !== null && input.tagName === 'IFRAME'

const removeCopilotWidgetElements = debounce(() => {
  const widgetElementIds = [
    WIDGET_SCRIPT_ID,
    WIDGET_IFRAME_ID,
    'botscrew-widget-background',
    'botscrew-custom-bubble-wrap',
  ]
  widgetElementIds
    .map(id => document.getElementById(id))
    .filter(element => element !== null)
    .forEach(element => element.remove())
}, 500)
