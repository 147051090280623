import { AnyAction } from 'redux'
import { SAVE_ACTIONS, SAVE_TOTAL_ELEMENTS, UPDATE_CURRENT_PAGE, SET_IS_LOADING_AI_ACTIONS } from '../actions/aiActions'
import { ActionParameterType } from '../components/AIActions/ActionModals/AddNewActionModal'

export type AIAction = {
  id: number
  name: string
  description: string
  handlerAtomId: number
  handlerAtom: string
  isActive: boolean
  params: ActionParameterType[]
}

interface AIActionState {
  aiActions: AIAction[]
  totalElements: number
  currentPage: number
  isLoadingAIActions: boolean
}

const initialState: AIActionState = {
  aiActions: [],
  totalElements: 0,
  currentPage: 0,
  isLoadingAIActions: true
}

const aiActionsSection = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SAVE_ACTIONS:
      return {
        ...state,
        aiActions: action.aiActions
      }
    case SAVE_TOTAL_ELEMENTS:
      return {
        ...state,
        totalElements: action.totalElements
      }
    case UPDATE_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage
      }
    case SET_IS_LOADING_AI_ACTIONS:
      return {
        ...state,
        isLoadingAIActions: action.isLoadingAIActions
      }
    default:
      return state
  }
}

export default aiActionsSection
