import styled from 'styled-components'
import { ActionButton } from '../../AIActionsTable.styles'

const ParametersTabContainer = styled.div`
  height: 100%;
`

const TabHeader = styled.div`
  border-bottom: 1px solid var(--color-input-stroke);
  padding: 16px 40px;
  display: flex;
  margin-bottom: 16px;
`

const TabHeaderTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  color: var(--color-text-secondary);
  max-width: ${props => props.maxWidth}px;
  flex: 1;
`

const TabBody = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
`

const AddButton = styled.button`
  color: var(--color-button-secondary);
  font-size: 16px;
  font-style: normal;
  width: max-content;
  padding: 0;
  border: none;
  background-color: transparent;
  margin-bottom: 16px;
`

const ParameterFormContainer = styled.div`
  border-radius: 8px;
  border: 1px solid var(--color-input-stroke);
  background: rgba(235, 235, 239, 0.10);
  padding: 16px;
  margin-bottom: 16px;
`

const SelectorsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`

const SelectorContainer = styled.div`
  margin-right: 16px;
  max-width: ${props => (props.maxWidth - 16)}px;
  flex: 1;
`

const ParameterDeleteButton = styled(ActionButton)`

`

export {
  ParametersTabContainer,
  TabHeader,
  TabHeaderTitle,
  TabBody,
  AddButton,
  ParameterFormContainer,
  SelectorsWrapper,
  SelectorContainer,
  ParameterDeleteButton
}
