import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { UnControlled as CodeMirror } from 'react-codemirror2'
import { EditorConfiguration } from 'codemirror'
import * as CM from 'codemirror'
import { Annotation, UpdateLintingCallback } from 'codemirror/addon/lint/lint'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/addon/lint/lint'
import 'codemirror/addon/lint/lint.css'
import 'codemirror/addon/lint/json-lint'
import 'codemirror/addon/edit/closebrackets'
import 'codemirror/addon/edit/matchbrackets'
import 'codemirror/addon/display/placeholder'
import 'codemirror/addon/hint/show-hint'
import 'codemirror/addon/hint/anyword-hint'
import 'codemirror/addon/hint/show-hint.css'
import 'codemirror/theme/idea.css'
import './CodeMirror.css'
import * as S from './JsonEditor.styles'
import AttributesList from '../AttributesList/AttributesList'
import { BotType } from 'models/BotType'
import { createAttribute } from '../../tabs/settings/api/attributes'
import { updateAttributeListScroll } from '../AttributesList/attributesListUtil'
import { ARROW_UP_KEY, ARROW_DOWN_KEY, ENTER_KEY } from '../../constants/keyCodes'

declare global {
  interface Window {
    jsonlint: any
  }
}

const ATTRIBUTE_TOKEN_PLACEHOLDER = '"ATTRIBUTE_TOKEN"'

const INDENT_SIZE = 2

const ATTRIBUTES_LIST_SIZE = {
  width: 200,
  height: 272,
}

interface JsonEditorProps {
  onChange: (text: string) => void
  initialValue: string
  placeholder?: string
  activeBot: BotType
  attributes: any[]
}

const JsonEditor: React.FC<JsonEditorProps> = ({
  onChange,
  initialValue,
  placeholder,
  activeBot,
  attributes,
  children,
}) => {
  const [showAttributesList, setShowAttributesList] = useState(false)
  const [searchAttribute, setSearchAttribute] = useState('')
  const [selectedAttribute, setSelectedAttribute] = useState(1)
  const [attributesListPosition, setAttributesListPosition] = useState({ top: 0, left: 0 })
  const attributesListRef = useRef(null)

  const codeMirrorRef = useRef(null)

  useEffect(() => {
    if (typeof window !== 'undefined' && !window.jsonlint) {
      window.jsonlint = require('jsonlint-mod')
    }
  }, [])

  const customLinter = (text: string, updateLintingCallback: UpdateLintingCallback) => {
    if (!text) {
      updateLintingCallback([])
      return
    }
    const customTokenRegex = /"?{{[a-z0-9_]+}}"?/g
    const tokens: string[] = []
    const cleanedText = text.replace(customTokenRegex, match => {
      tokens.push(match)
      return ATTRIBUTE_TOKEN_PLACEHOLDER
    })

    let annotations
    try {
      JSON.parse(cleanedText)

      annotations = checkKeysForSpaces(text)
    } catch (e) {
      annotations = handleLintError(e, text)
    } finally {
      updateLintingCallback(annotations)
    }
  }

  const checkKeysForSpaces = (text: string) => {
    const lines = text.split('\n')
    const annotations = []
    lines.forEach((line, lineIndex) => {
      checkLineKeysForSpaces(line, lineIndex, annotations)
    })
    return annotations
  }

  const checkLineKeysForSpaces = (line: string, lineIndex: number, annotations: Annotation[]) => {
    const keyWithSpacesRegex = /"[\w\s]*\s[\w\s]*":/g
    const matches = line.match(keyWithSpacesRegex)
    if (matches) {
      for (const matchingText of matches) {
        const matchStartIndex = line.indexOf(matchingText)
        const annotation = createKeyWithSpacesWarning(lineIndex, matchStartIndex, matchingText.length)
        annotations.push(annotation)
      }
    }
  }

  const createKeyWithSpacesWarning = (lineIndex: number, columnIndex: number, matchingTextLength): Annotation => {
    return {
      from: CM.Pos(lineIndex, columnIndex),
      to: CM.Pos(lineIndex, columnIndex + matchingTextLength),
      message: `JSON key with spaces (line ${lineIndex + 1} column ${columnIndex + 1})`,
      severity: 'warning',
    }
  }

  const handleLintError = (error, text: string): Annotation[] => {
    if (error instanceof Error) {
      const match = error.message.match(/line (\d+) column (\d+)/)
      if (match) {
        return getLintPositionalErrorAnnotations(error, match, text)
      }
      return getDefaultLintErrorAnnotations(text)
    }
  }

  const getLintPositionalErrorAnnotations = (error, match, text): Annotation[] => {
    const lineGroup = 1
    const charGroup = 2
    const lineIndex = parseInt(match[lineGroup]) - 1
    const charIndex = parseInt(match[charGroup]) - 1
    return [
      {
        from: CM.Pos(lineIndex, charIndex),
        to: CM.Pos(lineIndex, text.split('\n')[lineIndex].length),
        message: error.message,
        severity: 'error',
      },
    ]
  }

  const getDefaultLintErrorAnnotations = (text: string): Annotation[] => [
    {
      from: CM.Pos(0, 0),
      to: CM.Pos(text.split('\n').length - 1, text.split('\n').slice(-1)[0].length),
      message: 'Invalid JSON',
      severity: 'error',
    },
  ]

  const formatJson = (unformattedJson: string) => {
    const tokens: string[] = []
    let tokenizedJson = replaceAttributesWithIndexedPlaceholders(unformattedJson, tokens)

    try {
      const obj = JSON.parse(tokenizedJson)
      tokenizedJson = JSON.stringify(obj, null, INDENT_SIZE)
    } catch (e) {
      return unformattedJson
    }

    const formattedJson = replaceIndexedTokensWithAttributes(tokenizedJson, tokens)

    return formatIndents(formattedJson)
  }

  const replaceAttributesWithIndexedPlaceholders = (json: string, tokens: string[]) => {
    const tokenRegex = /"?{{[a-z0-9_]+}}"?/g
    return json.replace(tokenRegex, match => {
      tokens.push(match)
      return `"__ATTRIBUTE_TOKEN_${tokens.length - 1}__"`
    })
  }

  const replaceIndexedTokensWithAttributes = (tokenizedJson: string, tokens: string[]) =>
    tokenizedJson.replace(/"__ATTRIBUTE_TOKEN_(\d+)__"/g, (_, index) => tokens[parseInt(index)])

  const formatIndents = json => {
    const lines = json.split('\n')
    let indent = 0
    return lines
      .map(line => {
        line = line.trim()
        if (line.startsWith('}') || line.startsWith(']')) {
          indent -= INDENT_SIZE
        }
        const formattedLine = ' '.repeat(indent) + line
        if (line.endsWith('{') || line.endsWith('[')) {
          indent += INDENT_SIZE
        }
        return formattedLine
      })
      .join('\n')
  }

  const handleChange = (editor: CodeMirror.Editor, data: CodeMirror.EditorChange, value: string) => {
    handleAttributeInput(editor)
    onChange(value)
  }

  const handleAttributeInput = (editor: CodeMirror.Editor) => {
    const cursor = editor.getCursor()
    const line = editor.getLine(cursor.line)
    const substrBeforeCursor = line.substring(0, cursor.ch)

    const enteringAttributeRegex = /.*{{([a-z0-9_]+)$/
    const enteringAttributeRegexMatches = substrBeforeCursor.match(enteringAttributeRegex)

    if (substrBeforeCursor.endsWith('{{')) {
      openAttributeList(editor)
    } else if (enteringAttributeRegexMatches) {
      const searchAttribute = enteringAttributeRegexMatches[1]
      setSearchAttribute(searchAttribute)
    } else {
      closeAttributesList()
    }
  }

  const openAttributeList = (editor: CodeMirror.Editor) => {
    updateAttributesListPosition(editor)
    setShowAttributesList(true)
    const attributesListElement = attributesListRef.current
    attributesListElement.focus()
  }

  const addAttribute = attributeName => {
    const editor: CodeMirror.Editor = codeMirrorRef.current
    const cursor = editor.getCursor()
    const line = editor.getLine(cursor.line)

    insertAttribute(attributeName, editor, cursor, line)
    const charPositionAfterAttributeInserted = cursor.ch + attributeName.length

    addClosingCurlyBrackets(charPositionAfterAttributeInserted, editor, cursor, line)
    createIfNewAttribute(attributeName)
    closeAttributesList()

    const newCursor = { ...cursor, ch: charPositionAfterAttributeInserted + 2 } // Place the cursor after '}}'
    focusOnEditor(newCursor)
  }

  const insertAttribute = (attributeName: string, editor: CodeMirror.Editor, cursor, line: string) => {
    const substrBeforeCursor = line.substring(0, cursor.ch)
    const enteringAttributeRegex = /(.*\{\{)([a-z0-9_]*)$/
    const newString = substrBeforeCursor.replace(enteringAttributeRegex, function (match, p1, p2) {
      return p1 + attributeName
    })
    const from = {
      line: cursor.line,
      ch: 0,
    }
    const to = {
      line: cursor.line,
      ch: cursor.ch,
    }
    editor.replaceRange(newString, from, to)
  }

  const addClosingCurlyBrackets = (
    charPositionAfterAttributeInserted: number,
    editor: CodeMirror.Editor,
    cursor,
    line: string,
  ) => {
    const substrAfterCursor = line.substring(cursor.ch)
    if (!substrAfterCursor.startsWith('}}')) {
      editor.replaceRange('}}', { ...cursor, ch: charPositionAfterAttributeInserted })
    }
  }

  const createIfNewAttribute = attributeName => {
    const isNewAttribute = !attributes.some(attribute => attribute.name === attributeName)
    if (isNewAttribute) {
      createAttribute(activeBot.id, { name: attributeName }).then(closeAttributesList)
    }
  }

  const focusOnEditor = cursorPosition => {
    const editor: CodeMirror.Editor = codeMirrorRef.current
    editor.setCursor(cursorPosition)
    editor.focus()
  }

  const closeAttributesList = () => {
    setShowAttributesList(false)
    setSelectedAttribute(1)
    setSearchAttribute('')
  }

  const updateAttributesListPosition = (editor: CodeMirror.Editor) => {
    const position = getAttributeListPosition(editor)
    setAttributesListPosition(position)
  }

  const getAttributeListPosition = (editor: CodeMirror.Editor) => {
    const cursorCoords = editor.cursorCoords()
    const verticalOffset = 20

    let absoluteTop = cursorCoords.top + verticalOffset // Add offset to prevent the popup from overlapping the line
    let absoluteLeft = cursorCoords.left

    const fitsByHeight = absoluteTop + ATTRIBUTES_LIST_SIZE.height < window.innerHeight
    const fitsByWidth = absoluteLeft + ATTRIBUTES_LIST_SIZE.width < window.innerWidth
    if (!fitsByHeight) {
      absoluteTop = cursorCoords.top - ATTRIBUTES_LIST_SIZE.height
    }
    if (!fitsByWidth) {
      absoluteLeft = cursorCoords.left - ATTRIBUTES_LIST_SIZE.width
    }

    return { top: absoluteTop, left: absoluteLeft }
  }

  const alignCurrentLineIndent = (cm: CodeMirror.Editor) => {
    // CodeMirror does not add indentation after lines that end with attributes.
    // Align current line with the previous one.

    const cursor = cm.getCursor()
    const prevLine = cm.getLine(cursor.line - 1)

    const prevLineIndent = prevLine.match(/^\s*/)[0].length
    const currentLineIndent = cm.getLine(cursor.line).match(/^\s*/)[0].length

    if (currentLineIndent <= prevLineIndent) {
      cm.replaceRange(
        ' '.repeat(prevLineIndent),
        { line: cursor.line, ch: 0 },
        { line: cursor.line, ch: currentLineIndent },
      )
    }
  }

  const handleEnter = (cm: CodeMirror.Editor) => {
    if (showAttributesList) {
      addSelectedAttribute()
    } else {
      cm.execCommand('newlineAndIndent')
      alignCurrentLineIndent(cm)
    }
  }

  const addSelectedAttribute = () => {
    const selectedNode = attributesListRef?.current?.childNodes[selectedAttribute]
    const selectedValue = selectedNode?.childNodes[0]?.innerText || selectedNode?.innerText || searchAttribute
    if (selectedValue) {
      addAttribute(selectedValue)
    }
  }

  const handleUp = () => {
    return handleEditorArrowKey(ARROW_UP_KEY)
  }

  const handleDown = () => {
    return handleEditorArrowKey(ARROW_DOWN_KEY)
  }

  const handleEditorArrowKey = directionKey => {
    if (showAttributesList) {
      scrollAndSelectAttribute(directionKey)
    } else {
      return CM.Pass // Execute the default behavior
    }
  }

  const scrollAndSelectAttribute = directionKey => {
    const selectedAttributeIndex = updateAttributeListScroll(attributesListRef.current, directionKey, selectedAttribute)
    setSelectedAttribute(selectedAttributeIndex)
  }

  const handleAttributesListKeyDown = event => {
    const editor = codeMirrorRef.current
    if (event.keyCode === ENTER_KEY) {
      event.preventDefault()
      handleEnter(editor)
    } else if (event.keyCode === ARROW_UP_KEY || event.keyCode === ARROW_DOWN_KEY) {
      event.preventDefault()
      scrollAndSelectAttribute(event.keyCode)
    } else {
      const cmInputElement = editor.getInputField()
      const eventCopy = copyKeyDownEvent(event)
      cmInputElement.dispatchEvent(eventCopy)
    }
  }

  const copyKeyDownEvent = event =>
    new KeyboardEvent('keydown', {
      key: event.key,
      code: event.code,
      shiftKey: event.shiftKey,
      ctrlKey: event.ctrlKey,
      altKey: event.altKey,
      metaKey: event.metaKey,
      bubbles: true,
      cancelable: true,
    })

  const handleEditorDidMount = editor => {
    codeMirrorRef.current = editor

    // Don't set CodeMirror value as a prop as the cursor will be updated every time the value changes.
    editor.setValue(initialValue)
  }

  const options: EditorConfiguration = {
    mode: { name: 'application/json' },
    theme: 'idea',
    placeholder: placeholder,
    lineNumbers: true,
    gutters: ['CodeMirror-lint-markers'],
    lint: {
      getAnnotations: customLinter,
      async: true,
    },
    indentUnit: INDENT_SIZE,
    tabSize: INDENT_SIZE,
    lineWrapping: true,
    autoCloseBrackets: true,
    matchBrackets: true,
    extraKeys: {
      'Ctrl-Space': 'autocomplete',
      'Ctrl-Q': (cm: CodeMirror.Editor) => {
        const formatted = formatJson(cm.getValue())
        cm.setValue(formatted)
        cm.setCursor(cm.lineCount(), 0)
      },
      Enter: handleEnter,
      Up: handleUp,
      Down: handleDown,
    },
    hintOptions: {
      completeSingle: false,
    },
  }

  return (
    <S.EditorContainer>
      <S.EditorHoverButtonsWrap>{children}</S.EditorHoverButtonsWrap>
      <CodeMirror options={options} onChange={handleChange} editorDidMount={handleEditorDidMount} />
      <AttributesList
        addParam={addAttribute}
        show={showAttributesList}
        onClose={closeAttributesList}
        position={attributesListPosition}
        showSearch={false}
        searchValue={searchAttribute}
        listRef={attributesListRef}
        selectedAttribute={selectedAttribute}
        onKeyDown={handleAttributesListKeyDown}
        updateSelectedAttribute={setSelectedAttribute}
      />
      <S.FormatJsonHint>Press Ctrl+Q to format the JSON</S.FormatJsonHint>
    </S.EditorContainer>
  )
}

const mapStateToProps = state => ({
  attributes: state.attributes,
  activeBot: state.activeBot,
})

export default connect(mapStateToProps)(JsonEditor)
