import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { alertError, alertSuccess } from 'api'
import ModalTemplate from 'uiKit/ModalTemplate'
import Tooltip from 'uiKit/TooltipInfo/Tooltip'
import { isStringEmpty } from 'helpers/isStringEmpty'
import { GeneralTab } from './GeneralTab/GeneralTab'
import { ParametersTab } from './ParametersTab/ParametersTab'
import classes from '../../../../widget/components/AddGreetingModal/styles.module.scss'
import * as S from './AddNewActionModal.styles'
import { ModalActionFields } from '../../../constants/defaultValues'
import { AIAction } from '../../../reducers/aiActionsSection'
import { createAIAction, updateAIAction } from 'tabs/nlp/api/aiActions'
import { BotType } from 'models/BotType'

type TabType = 'General' | 'Parameters'
const tabs: TabType[] = ['General', 'Parameters']
const parametersTabTooltip =
  'Parameters will be recognized by AI and can be accessed through specified attributes in the selected atom.'

export type ActionParameterValueType = {
  id?: number
  value: string
}

export type ActionParameterType = {
  id: number
  attribute: string
  attributeId: number
  type: string
  description: string
  isRequired: boolean
  values: ActionParameterValueType[]
}

export type ActionFormType = {
  name: string
  description: string
  handlerAtom: string
  params: ActionParameterType[]
  id: number
}

const getDefaultParameter = () => {
  return {
    id: 0,
    attributeId: null,
    attribute: '',
    type: '',
    description: '',
    isRequired: false,
    values: [],
  }
}

interface Props {
  activeBot: BotType
  isOpenActionModal: boolean
  onClose: () => void
  selectedAction: AIAction
  setSelectedAction: Dispatch<SetStateAction<AIAction>>
}

const AddNewActionModal: FC<Props> = ({ activeBot, isOpenActionModal, onClose, selectedAction, setSelectedAction }) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0])
  const isGeneralTab = useMemo(() => selectedTab === tabs[0], [selectedTab])
  const [actionForm, setActionForm] = useState(
    selectedAction || {
      name: '',
      description: '',
      handlerAtomId: null,
      handlerAtom: '',
      id: 0,
      isActive: selectedAction?.isActive || false,
      params: [getDefaultParameter()],
    },
  )

  useEffect(() => {
    return () => setSelectedAction(null)
  }, [])

  const handleSave = () => {
    if (selectedTab === tabs[0]) {
      setSelectedTab(tabs[1])
    } else {
      const errorMessage = validateAction()
      if (errorMessage) {
        alertError(errorMessage)
      } else {
        upsertAIAction()
        onClose()
      }
    }
  }

  const upsertAIAction = () => {
    if (actionForm.id) {
      updateAIAction(activeBot.id, actionForm).then(() => alertSuccess('AI Action updated successfully.'))
    } else {
      createAIAction(activeBot.id, actionForm)
    }
  }

  const validateAction = () => {
    if (isStringEmpty(actionForm.name)) {
      setSelectedTab(tabs[0])
      return 'Please write action name'
    } else if (actionForm.name.length > 64) {
      setSelectedTab(tabs[0])
      return 'AI action name should be less than 65 symbols'
    } else if (isStringEmpty(actionForm.description)) {
      setSelectedTab(tabs[0])
      return 'Please write description for AI'
    } else if (isStringEmpty(actionForm.handlerAtom)) {
      setSelectedTab(tabs[0])
      return 'Please select atom to trigger'
    } else if (actionForm.params.some(p => !p.attributeId)) {
      return 'Please select parameters attribute'
    } else if (actionForm.params.some(p => !p.type)) {
      return 'Please select parameters type'
    } else if (actionForm.params.some(p => !p.description)) {
      return 'Please select parameters description'
    }

    return false
  }

  const closeOrCancelHandler = (e, hasPressedXButton: boolean) => {
    if (hasPressedXButton) {
      onClose()
    } else {
      setSelectedTab(tabs[0])
    }
  }

  const onChangeHandler = (type: ModalActionFields, value: any, parameterIndex?: number) => {
    setActionForm(prevState => {
      if (parameterIndex === undefined) {
        return {
          ...prevState,
          [type]: value,
        }
      } else {
        const updatedParameters = [...prevState.params]
        updatedParameters[parameterIndex] = {
          ...updatedParameters[parameterIndex],
          ...(type === ModalActionFields.VALUES ? { values: [...value] } : { [type]: value }),
        }

        const updatedAction = {
          ...prevState,
          params: updatedParameters,
        }
        return updatedAction
      }
    })
  }

  const addNewParameter = () => {
    setActionForm(prevState => {
      const updatedParameters = [...prevState.params]
      updatedParameters.push(getDefaultParameter())
      return {
        ...prevState,
        params: updatedParameters,
      }
    })
  }

  const deleteParameter = parameterIndex => {
    setActionForm(prevState => {
      const updatedParameters = [...prevState.params]
      updatedParameters.splice(parameterIndex, 1)
      return {
        ...prevState,
        params: updatedParameters,
      }
    })
  }

  const tabProcessor = (tab: string) => {
    return tab === tabs[1] ? (
      <S.TabContainer>
        <S.TabTitle>{tab}</S.TabTitle>
        <Tooltip tooltipId={'TooltipInfo-parameters-tab'} tooltipText={parametersTabTooltip} />
      </S.TabContainer>
    ) : (
      tab
    )
  }

  return (
    <ModalTemplate
      open={isOpenActionModal}
      title="New AI action"
      onClose={closeOrCancelHandler}
      onSave={handleSave}
      hasCancelButton={!isGeneralTab}
      cancelButtonTitle="Back"
      saveButtonTitle={isGeneralTab ? 'Next' : 'Save'}>
      <>
        <div className={classes.tabs}>
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={selectedTab === tab ? classes.activeTab : classes.tab}
              // activeTab class has white-space: no-wrap. Turning it off for correct Tooltip rendering
              style={{ whiteSpace: 'normal' }}
              onClick={() => setSelectedTab(tab)}>
              {tabProcessor(tab)}
            </div>
          ))}
        </div>
        <div className={classes.body} style={{ padding: 0 }} id="scrollable">
          {selectedTab === tabs[0] && <GeneralTab actionForm={actionForm} onChangeHandler={onChangeHandler} />}
          {selectedTab === tabs[1] && (
            <ParametersTab
              parameters={actionForm.params}
              onChangeHandler={onChangeHandler}
              addNewParameter={addNewParameter}
              deleteParameter={deleteParameter}
            />
          )}
        </div>
      </>
    </ModalTemplate>
  )
}

const mapStateToProps = (state: { activeBot: BotType }) => ({
  activeBot: state.activeBot,
})

export default connect(mapStateToProps)(AddNewActionModal)
