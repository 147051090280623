import React, { Dispatch, FC, SetStateAction } from 'react'
import { connect, useSelector } from 'react-redux'
import Reducers from 'reducers'
import Table from 'uiKit/table/Table'
import TableRow from 'uiKit/table/TableRow'
import TableCell from 'uiKit/table/TableCell'
import { EditIcon } from 'uiKit/icons/EditIcon'
import { DeleteTrashIcon } from 'uiKit/icons/DeleteTrashIcon'
import Switch from 'uiKit/Switch'
import { AIAction } from '../../reducers/aiActionsSection'
import * as S from './AIActionsTable.styles'
import { BotType } from 'models/BotType'
import { updateAIAction } from 'tabs/nlp/api/aiActions'
import { alertSuccess } from '../../../../api'

const columnTitles = [
  { title: 'Name', maxWidth: 280 },
  { title: 'Atom to trigger', maxWidth: 850 },
  { title: 'Active', maxWidth: 120 },
  { title: '', maxWidth: 64 },
]

const getRowBackground = (index: number) => {
  return { background: index % 2 === 0 ? '' : 'var(--color-table-row-primary)' }
}

interface Props {
  activeBot: BotType
  setIsOpenDeleteModal: Dispatch<SetStateAction<boolean>>
  setSelectedAction: Dispatch<SetStateAction<AIAction>>
  editActionHandler: (action: AIAction) => void
}

const AIActionsTable: FC<Props> = ({ activeBot, setIsOpenDeleteModal, setSelectedAction, editActionHandler }) => {
  const {
    aiActionsSection: { aiActions },
  } = useSelector((state: ReturnType<typeof Reducers>) => state)

  const isActiveChangeHandler = (aiAction: AIAction) => {
    return e => {
      const aiActionCopy = { ...aiAction }
      aiActionCopy.isActive = !aiActionCopy.isActive
      const message = aiActionCopy.isActive ? 'AI Action enabled.' : 'AI Action disabled.'
      updateAIAction(activeBot.id, aiActionCopy).then(() => alertSuccess(message))
    }
  }

  const handleDeleteClick = (aiAction: AIAction) => {
    setIsOpenDeleteModal(true)
    setSelectedAction(aiAction)
  }

  return (
    <Table titles={columnTitles}>
      {aiActions.map((action: AIAction, index) => (
        <TableRow
          key={action.id}
          style={{
            alignItems: 'center',
            ...getRowBackground(index),
          }}>
          <TableCell style={{ maxWidth: columnTitles[0].maxWidth }}>{action.name}</TableCell>
          <TableCell style={{ maxWidth: columnTitles[1].maxWidth }}>{action.handlerAtom}</TableCell>
          <TableCell style={{ maxWidth: columnTitles[2].maxWidth }}>
            <Switch useSecondaryColor={true} checked={action.isActive} onChange={isActiveChangeHandler(action)} />
          </TableCell>
          <TableCell style={{ maxWidth: columnTitles[3].maxWidth }}>
            <S.ActionButtonsContainer>
              <S.ActionButton onClick={() => editActionHandler(action)}>
                <EditIcon color="var(--color-text-secondary)" />
              </S.ActionButton>
              <S.ActionButton onClick={() => handleDeleteClick(action)}>
                <DeleteTrashIcon />
              </S.ActionButton>
            </S.ActionButtonsContainer>
          </TableCell>
        </TableRow>
      ))}
    </Table>
  )
}

const mapStateToProps = (state: { activeBot: BotType }) => ({
  activeBot: state.activeBot,
})

export default connect(mapStateToProps)(AIActionsTable)
