import React, { FC, useState } from 'react'
import Checkbox from '../../../../../../uiKit/Checkbox'
import Select from 'react-select'
import { customStyles } from 'uiKit/AtomSelect/styles'
import AttributeSelect from 'uiKit/AttributeSelect/AttributeSelect'
import { DeleteTrashIcon } from 'uiKit/icons/DeleteTrashIcon'
import { CHATBOT, DEFAULT } from 'constants/attributeTypes'
import { ActionParameterType } from '../AddNewActionModal'
import { DescriptionTextarea } from '../../../DescriptionTextarea/DescriptionTextarea'
import { ModalActionFields, typeOptions } from '../../../../constants/defaultValues'
import { ParameterValues } from '../../ParameterValues/ParameterValues'
import * as S from './ParametersTab.styles'

const PARAMETER_DESCRIPTION_PLACEHOLDER = 'Parameter description: Ex. The city and state, e.g. San Francisco, CA'

interface Props {
  parameterIndex: number
  parameter: ActionParameterType
  onChangeHandler: (type: ModalActionFields, value: string | boolean, parameterIndex?: number) => void
  deleteParameter: (parameterIndex: number) => void
}

export const ParameterForm: FC<Props> = ({ parameterIndex, parameter, onChangeHandler, deleteParameter }) => {
  const [focus, setFocus] = useState(false)

  const handleUpdateAttribute = e => {
    const selectedAttribute = {
      id: e?.value,
      name: e?.label,
    }
    onChangeHandler(ModalActionFields.ATTRIBUTE_ID, selectedAttribute.id, parameterIndex)
  }

  const handleUpdateType = typeOption => {
    onChangeHandler(ModalActionFields.TYPE, typeOption.value, parameterIndex)
  }

  const onCheckBoxChange = value => {
    onChangeHandler(ModalActionFields.IS_REQUIRED, value, parameterIndex)
  }

  const onChangeDescription = value => {
    onChangeHandler(ModalActionFields.PARAMETER_DESCRIPTION, value, parameterIndex)
  }

  const setParameterValues = value => {
    onChangeHandler(ModalActionFields.VALUES, value, parameterIndex)
  }

  const parameterTypeOption = typeOptions.find(option => option.value === parameter.type)

  return (
    <S.ParameterFormContainer>
      <S.SelectorsWrapper>
        <S.SelectorContainer maxWidth={230}>
          <AttributeSelect
            placeholder="Select attribute"
            focus={focus || !parameter.attributeId}
            value={parameter.attributeId}
            onChange={handleUpdateAttribute}
            onClick={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            defaultAttributes={['language', 'close_conversation', 'conversation_outcome']}
            isClearable={false}
            hasDropdownIndicator={true}
            displayOptions={[DEFAULT, CHATBOT]}
            styles={{
              borderRadius: 10,
              height: 42,
              padding: '2px 6px',
              hasDropdownIndicator: true,
            }}
          />
        </S.SelectorContainer>
        <S.SelectorContainer maxWidth={180}>
          <Select
            placeholder="Select type"
            styles={customStyles}
            options={typeOptions}
            onChange={handleUpdateType}
            value={parameterTypeOption}
            isSearchable={false}
          />
        </S.SelectorContainer>
        <div style={{ height: 42, marginRight: 'auto', transform: 'translateY(-15%)' }}>
          <Checkbox label="" checked={parameter.isRequired} onChange={checked => onCheckBoxChange(checked)} />
        </div>
        <S.ParameterDeleteButton onClick={() => deleteParameter(parameterIndex)}>
          <DeleteTrashIcon />
        </S.ParameterDeleteButton>
      </S.SelectorsWrapper>
      <DescriptionTextarea
        onChangeHandler={onChangeDescription}
        value={parameter.description}
        placeholder={PARAMETER_DESCRIPTION_PLACEHOLDER}
        minHeight={100}
        maxHeight={200}
      />
      <ParameterValues parameterValues={parameter.values} setParameterValues={setParameterValues} />
    </S.ParameterFormContainer>
  )
}
