import React from 'react'
import SwitchM from '@material-ui/core/Switch'
import classes from './styles.module.scss'
import { withStyles } from '@material-ui/core'
import { getColorButtonPrimary50, getColorButtonSecondary50 } from '../../globalStylesHelper'

interface Props {
  useSecondaryColor?: boolean
  label?: string
  checked: boolean
  onChange: (a: any) => void
  style?: any
}

const colorButtonPrimary50 = getColorButtonPrimary50()
const colorButtonSecondary50 = getColorButtonSecondary50()

export const PrimaryColorSwitch = withStyles(theme => ({
  switchBase: {
    '&$checked': {
      transform: 'translateX(16px)',
      color: 'var(--color-button-primary)',
      '&$checked + $bar': {
        backgroundColor: `${colorButtonPrimary50}`,
        opacity: 1,
        border: 'none',
      },
    },
  },
  checked: {},
  bar: {},
}))(SwitchM)

export const SecondaryColorSwitch = withStyles(theme => ({
  switchBase: {
    '&$checked': {
      transform: 'translateX(16px)',
      color: 'var(--color-button-secondary)',
      '&$checked + $bar': {
        backgroundColor: `${colorButtonSecondary50}`,
        opacity: 1,
        border: 'none',
      },
    },
  },
  checked: {},
  bar: {},
}))(SwitchM)

const Switch: React.FC<Props> = ({ label, checked, onChange, useSecondaryColor, style = {} }) => {
  return (
    <div className={classes.container} style={style}>
      {useSecondaryColor ? (
        <SecondaryColorSwitch checked={checked} onChange={onChange} />
      ) : (
        <PrimaryColorSwitch checked={checked} onChange={onChange} />
      )}
      {label && <p className={classes.inputTitle}>{label}</p>}
    </div>
  )
}

export default Switch
