import styled from 'styled-components'
import { DescriptionLabel } from '../../../DescriptionTextarea/DescriptionTextarea.styles'

const GeneralTabContainer = styled.div`
  padding: 0 100px;
`

const InputContainer = styled.div`
  margin-bottom: 16px;
`

const InputContainerLabel = styled(DescriptionLabel)`
  margin-bottom: 8px;
  color: var(--color-text-primary);
`

const SelectText = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--color-text-secondary);
  margin-top: 8px;
`

export { GeneralTabContainer, InputContainer, InputContainerLabel, SelectText }
