export const defaultValueLanguage = { value: 'all', label: 'All languages' }
export const defaultValueReplySource = { value: 'all', label: 'All sources' }
export const defaultValueIntent = { value: 'all', label: 'All intents' }
export const defaultFallbackIntent = 'Default Fallback Intent'

export const ModalActionFields = {
  ACTION_NAME: 'name',
  DESCRIPTION: 'description',
  HANDLER_ATOM: 'handlerAtom',
  HANDLER_ATOM_ID: 'handlerAtomId',
  ATTRIBUTE_ID: 'attributeId',
  TYPE: 'type',
  PARAMETER_DESCRIPTION: 'description',
  IS_REQUIRED: 'isRequired',
  VALUES: 'values',
}

export const typeOptions = [
  {
    label: 'String',
    value: 'STRING',
  },
  {
    label: 'Number',
    value: 'NUMBER',
  },
  {
    label: 'Boolean',
    value: 'BOOLEAN',
  },
  {
    label: 'String array',
    value: 'STRING_ARRAY',
  },
  {
    label: 'Number array',
    value: 'NUMBER_ARRAY',
  },
]
