import styled from 'styled-components'

const SearchContainer = styled.div`
  margin: 8px 12px;
`

const SectionWrap = styled.div`
  overflow-y: auto;
  height: 100%;
`

export { SearchContainer, SectionWrap }
