import styled from 'styled-components'

const TextMessage = styled.span<{ isReceived?: boolean; isRtl?: boolean; highlight?: boolean }>`
  max-width: 78%;
  padding: 8px 16px;
  font-size: 14px;
  display: inline-block;
  border-radius: ${({ isReceived }) => (isReceived ? '10px 10px 10px 2px' : '10px 10px 2px 10px')};
  color: ${({ isReceived }) => (isReceived ? 'var(--color-shark-black)' : 'var(--color-text-on-primary)')};
  direction: ${({ isRtl }) => (isRtl ? 'rtl' : 'ltr')};
  border: ${({ highlight }) => highlight && '1px solid var(--color-general-ui)'};
  background: ${({ isReceived, highlight }) => {
    if (highlight) {
      return 'var(--color-general-ui)'
    } else if (isReceived) {
      return 'var(--color-table-row-primary)'
    }
    return 'var(--color-general-ui)'
  }};
  span {
    cursor: pointer;
    text-decoration: underline;
    &[style] {
      color: var(--color-white) !important;
    }
  }
`
export { TextMessage }
