import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import LoaderScreen from 'uiKit/loaders/loaderScreen'
import Headline from 'uiKit/texts/Headline'
import Submenu from 'uiKit/submenu/Submenu'
import DateControl from 'uiKit/DateControlNew'
import EmptyNlpScreen from './components/EmptyNlpScreen'

import { HOME_PATH } from 'configs'
import { DateControlProvider } from 'contexts/DateControlContext'
import { getFlows } from '../flowBuilder/api/flow'
import { shouldShowDatePickers } from 'helpers/shouldShowDatePickers'
import { setBrowserTabTitle } from '../../helpers/setBrowserTabTitle'
import { getBotLanguages } from '../settings/api/settings'
import { getAttributes } from '../settings/api/attributes'
import { saveBotLanguages } from './actions/botLanguages'
import { ROLE_OPTIONS } from '../../security'
import { AdminUserType } from 'models/AdminUserType'
import { BotType } from 'models/BotType'
import { MatchType } from 'models/MatchType'

import * as S from './NlpContainer.style'

interface Props {
  activeBot: BotType
  match: MatchType
  adminUser: AdminUserType
}

const NlpContainer: React.FC<Props> = ({ activeBot, match, adminUser }) => {
  const [loader, setLoader] = useState(true)
  const pathLink = `${HOME_PATH}/bot/${activeBot?.id}/nlp`
  const pathRoute = `${HOME_PATH}/bot/:botId/nlp/`

  const getAllowedNlpRoutes = () => {
    const botAccess = adminUser.botAccesses.find(access => access.botId == match.params.botId)
    const roleOption = ROLE_OPTIONS.find(option => option.value === botAccess.role)
    return roleOption.nlpRoutes
  }
  const allowedNlpRoutes = getAllowedNlpRoutes()

  useEffect(() => {
    getFlows(match.params.botId)
    getBotLanguages(match.params.botId).then(languages => {
      languages && saveBotLanguages(languages)
      return languages
    }).then((languages) => {
      const { defaultLanguage } = languages
      getAttributes(match.params.botId, defaultLanguage.shortName)
    })
    setLoader(false)
  }, [])

  useEffect(() => {
    setBrowserTabTitle(`${activeBot?.name} - NLP`)
  }, [activeBot])

  return (
    <S.Container>
      {loader && <LoaderScreen />}

      {!loader && (
        <DateControlProvider>
          <S.HeadlineWrap>
            <Headline title="NLP Training" />
            {activeBot?.nlpEnabled && shouldShowDatePickers() && <DateControl />}
          </S.HeadlineWrap>
          {activeBot?.nlpEnabled ? (
            <Submenu pathLink={pathLink} pathRoute={pathRoute} routes={allowedNlpRoutes} />
          ) : (
            <EmptyNlpScreen />
          )}
        </DateControlProvider>
      )}
    </S.Container>
  )
}

const mapStateToProps = (state: { activeBot: BotType; adminUser: AdminUserType }) => ({
  activeBot: state.activeBot,
  adminUser: state.adminUser,
})

export default withRouter(connect(mapStateToProps)(NlpContainer))
