import React, { FC, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { EnterIcon } from 'uiKit/icons/Icons'
import { ActionParameterValueType } from '../ActionModals/AddNewActionModal'
import Tooltip from 'uiKit/TooltipInfo/Tooltip'
import UserSays from '../../UserSays'
import * as S from './ParameterValues.styles'

// eslint-disable-next-line max-len
const tooltipText =
  'This is optional and applicable only if the parameter has limited possible values. For example, ' +
  'a parameter called "cardinal directions" might only accept "North", "South", "East", and "West".'

interface Props {
  parameterValues: ActionParameterValueType[]
  setParameterValues: any
}

export const ParameterValues: FC<Props> = ({ parameterValues, setParameterValues }) => {
  const [text, setText] = useState('')
  const [error, setError] = useState('')
  const [emptySay, setEmptySay] = useState(false)
  const [editInput, setEditInput] = useState(false)

  const addParameterValue = () => {
    const newParameterValues = [...parameterValues]
    newParameterValues.unshift({ value: text })

    setParameterValues(newParameterValues)
    setError('')
    setText('')
  }

  const handleAddParameterValue = () => {
    const currentParameterValues = parameterValues || []
    const isNotUnique = currentParameterValues
      .map(param => param.value)
      .some(elem => elem.trim().toUpperCase() === text.trim().toUpperCase())

    if (isNotUnique) {
      setError(text)
    } else if (text !== '') {
      addParameterValue()
    }
  }

  const handleEditUserPhrase = (index, event) => {
    const currentParameterValues = [...parameterValues]

    const isEdit = currentParameterValues
      .map(param => param.value)
      .some((elem, i) => {
        const isEditPhrase = elem.toUpperCase() === event.target.value.trim().toUpperCase() && index !== i
        if (isEditPhrase) {
          setError(event.target.value)
          setEditInput(index)
        }
        return isEditPhrase
      })

    if (!isEdit && event.target.value !== '') {
      const newParameterValues = parameterValues.map(value => ({ ...value }))
      const valueToUpdate = { ...parameterValues[index] }
      valueToUpdate.value = event.target.value
      newParameterValues.splice(index, 1, valueToUpdate)

      setParameterValues(newParameterValues)
      setError('')
      setEditInput(null)
    }
  }

  const handleDeleteUserPhrase = index => {
    const newParameterValues = [...parameterValues]
    newParameterValues.splice(index, 1)

    setParameterValues(newParameterValues)
    setEditInput(null)
  }

  const handleModifyInput = value => {
    setEditInput(value)
  }

  return (
    <S.InputWrapper>
      <S.InputLabelContainer>
        <S.InputLabel>Values (optional)</S.InputLabel>
        <Tooltip tooltipId={`TooltipInfo-parameter-${uuid()}`} tooltipText={tooltipText} />
      </S.InputLabelContainer>
      <S.InputContainer>
        <S.Input
          maxLength={255}
          value={text || ''}
          placeholder="+ Type a value and press Enter to add it"
          onChange={e => setText(e.target.value)}
          onKeyPress={e => e.charCode === 13 && handleAddParameterValue()}
        />
        <S.EnterButton onClick={handleAddParameterValue}>
          <EnterIcon width={14} height={14} color="var(--color-malachite-green)" />
        </S.EnterButton>
        {error && <S.Alert>{`Parameter Value '${error}' already exist`}</S.Alert>}
      </S.InputContainer>
      <S.ParameterValuesWrapper>
        {parameterValues.map((value, index) => (
          <div key={value.value}>
            <UserSays
              says={value.value}
              emptySay={emptySay}
              editInput={editInput}
              modifyInput={handleModifyInput}
              setEmptySay={setEmptySay}
              editUserPhrase={handleEditUserPhrase}
              deleteUserPhrase={handleDeleteUserPhrase}
              index={index}
            />
          </div>
        ))}
      </S.ParameterValuesWrapper>
    </S.InputWrapper>
  )
}
