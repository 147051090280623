import styled from 'styled-components'

const TabContainer = styled.div`
  display: flex;

`

const TabTitle = styled.span`
  margin-right: 4px;
`

export { TabTitle, TabContainer }
