import React, { ReactNode } from 'react'

interface InfoAlertProps {
  title: string | ReactNode
  closeable: boolean
  onClose: any
}

const InfoAlert: React.FC<InfoAlertProps> = ({ title, closeable, onClose }: InfoAlertProps) => {
  return (
    <div
      className="alert alert-primary alert-dismissible fade show my-4"
      style={{
        fontSize: '14px',
        backgroundColor: 'var(--color-hover-overlay-primary)',
        color: 'var(--color-text-primary)',
        paddingTop: '10px',
        paddingBottom: '10px',
      }}
      role="alert">
      {title}
      {closeable && (
        <button
          type="button"
          onClick={onClose}
          className="btn-close"
          data-bs-dismiss="alert"
          style={{ top: '50%', transform: 'translateY(-50%)' }} // Center by Y
          aria-label="Close"></button>
      )}
    </div>
  )
}

export default InfoAlert
