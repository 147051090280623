import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import Reducers from 'reducers'
import { DateControlContext } from 'contexts/DateControlContext'
import { useDebounce } from 'hooks/useDebounce'
import Search from 'uiKit/Search/Search'
import LanguageSelector from 'uiKit/LanguageSelector/LanguageSelector'
import Pagination from 'uiKit/table/Pagination'
import NoChartData from 'uiKit/NoChartData'
import LoaderScreen from 'uiKit/loaders/loaderScreen'
import { defaultValueLanguage, defaultValueReplySource } from 'tabs/nlp/constants/defaultValues'
import SelectorStyles from 'tabs/nlp/constants/selectorStyles'
import { ROWS_PER_PAGE } from 'tabs/nlp/constants/paginationSize'
import { getKnowledgeBaseReplies, getSuccessfulKnowledgeBaseReplyCount } from 'tabs/nlp/api/websiteKnowledgeBase'

import * as S from './KnowledgeBaseReplies.style'
import { KnowledgeBaseRepliesTable } from './KnowledgeBaseRepliesTable'
import KnowledgeReplySourceSelect from '../KnowledgeReplySourceSelect'

const inputSearchStyles = { borderRadius: 10, height: '45px', padding: '14px 24px', minWidth: 200 }
const debounceDelay = 500

export const KnowledgeBaseReplies: FC = () => {
  const { dateRange } = useContext(DateControlContext)
  const botId: number = useSelector((state: ReturnType<typeof Reducers>) => state.activeBot.id)
  const [loadingReplies, setLoadingReplies] = useState(true)
  const [knowledgeBaseReplies, setKnowledgeBaseReplies] = useState({ data: [], totalElements: 0 })

  const [loadingSuccessfulReplyCount, setLoadingSuccessfulReplyCount] = useState(true)
  const [successfulReplyCount, setSuccessfulReplyCount] = useState(0)

  const [selectedReplySource, setSelectedReplySource] =
    useState<{ value: string; label: string }>(defaultValueReplySource)

  const [searchValue, setSearchValue] = useState('')
  const debouncedSearch = useDebounce(searchValue, debounceDelay)
  const [selectedLanguage, setSelectedLanguage] =
    useState<{ value: string; label: string; id?: number }>(defaultValueLanguage)
  const [page, setPage] = useState(0)

  useEffect(() => {
    setPage(0)
    fetchKnowledgeReplies(0)
  }, [debouncedSearch, dateRange, selectedLanguage, selectedReplySource])

  const fetchKnowledgeReplies = (page: number) => {
    getKnowledgeBaseReplies({
      botId,
      page,
      size: ROWS_PER_PAGE,
      startDate: dateRange.startDate.format('YYYY-MM-DD'),
      endDate: dateRange.endDate.format('YYYY-MM-DD'),
      languageId: selectedLanguage.id || selectedLanguage.value,
      sourceTitle: selectedReplySource.value,
      search: debouncedSearch.toLowerCase(),
    }).then(res => {
      setLoadingReplies(false)
      setKnowledgeBaseReplies(res)
    })
  }

  useEffect(() => {
    updateSuccessfulKnowledgeReplyCount()
  }, [dateRange, selectedLanguage, selectedReplySource])

  const updateSuccessfulKnowledgeReplyCount = () => {
    setLoadingSuccessfulReplyCount(true)
    const requestParams = {
      botId: botId,
      languageId: selectedLanguage.id || selectedLanguage.value,
      sourceTitle: selectedReplySource.value,
      startDate: dateRange.startDate.format('YYYY-MM-DD'),
      endDate: dateRange.endDate.format('YYYY-MM-DD'),
    }
    getSuccessfulKnowledgeBaseReplyCount(requestParams).then(res => {
      setLoadingSuccessfulReplyCount(false)
      setSuccessfulReplyCount(res.count)
    })
  }

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const handleChangePage = (_, page) => {
    setPage(page)
    fetchKnowledgeReplies(page)
  }

  return (
    <S.KnowledgeBaseContainer>
      <S.TableTitleWithFilters>
        <S.Header>
          <S.TitleWrap>
            <S.Title>AI Knowledge replies</S.Title>
          </S.TitleWrap>
        </S.Header>
        <S.FiltersWrap>
          <S.SearchWrap>
            <Search
              value={searchValue}
              onChange={handleSearchChange}
              placeholder="Search"
              inputStyle={inputSearchStyles}
            />
          </S.SearchWrap>
          <S.SelectorWrap>
            <KnowledgeReplySourceSelect
              value={selectedReplySource}
              onChange={setSelectedReplySource}
              styles={SelectorStyles}
            />
          </S.SelectorWrap>
          <S.SelectorWrap>
            <LanguageSelector
              onChange={setSelectedLanguage}
              value={selectedLanguage}
              defaultValue={defaultValueLanguage}
              styles={SelectorStyles}
            />
          </S.SelectorWrap>
        </S.FiltersWrap>
      </S.TableTitleWithFilters>
      {(loadingReplies || loadingSuccessfulReplyCount) && <LoaderScreen />}

      {!loadingSuccessfulReplyCount && (
        <S.SuccessfulReplyCountWrap>Successful AI replies: {successfulReplyCount}</S.SuccessfulReplyCountWrap>
      )}

      {!loadingReplies &&
        (knowledgeBaseReplies.data.length ? (
          <>
            <KnowledgeBaseRepliesTable
              knowledgeBaseReplies={knowledgeBaseReplies.data}
              botId={botId}
              page={page}
              fetchKnowledgeReplies={fetchKnowledgeReplies}
            />
            <Pagination
              page={page}
              rowsPerPage={ROWS_PER_PAGE}
              count={knowledgeBaseReplies.totalElements}
              onChangePage={handleChangePage}
            />
          </>
        ) : (
          <NoChartData text="No data during selected time range" />
        ))}
    </S.KnowledgeBaseContainer>
  )
}
