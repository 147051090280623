import { store } from '../../../index'

export const SET_IS_LOADING_AI_ACTIONS = 'SET_IS_LOADING_AI_ACTIONS'
export const SAVE_ACTIONS = 'SAVE_ACTIONS'
export const SAVE_TOTAL_ELEMENTS = 'SAVE_TOTAL_ELEMENTS'
export const UPDATE_CURRENT_PAGE = 'UPDATE_CURRENT_PAGE'

export const setIsLoadingAIActions = (isLoadingAIActions) => {
  store.dispatch({
    type: SET_IS_LOADING_AI_ACTIONS,
    isLoadingAIActions
  })
}

export const saveActions = (aiActions) => {
  store.dispatch({
    type: SAVE_ACTIONS,
    aiActions
  })
}

export const saveTotalElements = (totalElements) => {
  store.dispatch({
    type: SAVE_TOTAL_ELEMENTS,
    totalElements
  })
}

export const updateCurrentPage = (currentPage) => {
  store.dispatch({
    type: UPDATE_CURRENT_PAGE,
    currentPage
  })
}

export const getCurrentPage = () => {
  return store.getState().aiActionsSection.currentPage
}
