import React, { FC } from 'react'
import Input from 'uiKit/inputs/Input'
import AtomSelect from 'uiKit/AtomSelect/AtomSelect'
import { ActionFormType } from '../AddNewActionModal'
import { DescriptionTextarea } from '../../../DescriptionTextarea/DescriptionTextarea'
import { ModalActionFields } from '../../../../constants/defaultValues'
import * as S from './GeneralTab.styles'

const DESCRIPTION_TOOLTIP = 'The description should explain the purpose of the action and when it should be called'

const ACTION_DESCRIPTION_PLACEHOLDER = 'Action description: Ex. Get the current weather in a given location.'

interface Props {
  actionForm: ActionFormType
  onChangeHandler: (type: ModalActionFields, value: string | boolean, parameterIndex?: number) => void
}

export const GeneralTab: FC<Props> = ({ actionForm, onChangeHandler }) => {
  const descriptionChangeHandler = value => {
    onChangeHandler(ModalActionFields.DESCRIPTION, value)
  }

  const handleAtomChange = atomOption => {
    const { label, value } = atomOption
    onChangeHandler(ModalActionFields.HANDLER_ATOM, label)
    onChangeHandler(ModalActionFields.HANDLER_ATOM_ID, value)
  }

  return (
    <S.GeneralTabContainer>
      <S.InputContainer>
        <Input
          title="AI action name"
          placeholder="Enter here"
          value={actionForm.name}
          onChange={e => onChangeHandler(ModalActionFields.ACTION_NAME, e.target.value)}
          style={{ border: '1px solid var(--color-platinum)', color: 'var(--color-oxford-blue)' }}
        />
      </S.InputContainer>
      <DescriptionTextarea
        label="Description for AI"
        placeholder={ACTION_DESCRIPTION_PLACEHOLDER}
        tooltipText={DESCRIPTION_TOOLTIP}
        value={actionForm.description}
        onChangeHandler={descriptionChangeHandler}
        minHeight={150}
        maxHeight={300}
      />
      <S.InputContainer>
        <S.InputContainerLabel>Atom to trigger</S.InputContainerLabel>
        <AtomSelect value={actionForm.handlerAtom} onChange={handleAtomChange} />
        <S.SelectText>
          Make sure you set the <span className="fw-bold">last_ai_action_result</span> attribute in the atom so that it
          can be used by the AI to generate a response
        </S.SelectText>
      </S.InputContainer>
    </S.GeneralTabContainer>
  )
}
