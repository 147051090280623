import React, { FC } from 'react'
import AutoResizingTextarea from 'uiKit/AutoResizingTextarea/AutoResizingTextarea'
import Tooltip from 'uiKit/TooltipInfo/Tooltip'
import * as S from './DescriptionTextarea.styles'

interface Props {
  label?: string
  tooltipText?: string
  onChangeHandler: (value: string) => void
  value: string
  placeholder?: string
  minHeight: number
  maxHeight: number
}

export const DescriptionTextarea: FC<Props> = ({
  label,
  tooltipText,
  onChangeHandler,
  value,
  placeholder,
  minHeight,
  maxHeight,
}) => {
  return (
    <S.DescriptionTextareaContainer>
      {label && (
        <S.DescriptionLabelContainer>
          <S.DescriptionLabel> {label} </S.DescriptionLabel>
          {tooltipText && <Tooltip tooltipId={'TooltipInfo-attribute'} tooltipText={tooltipText} />}
        </S.DescriptionLabelContainer>
      )}
      <AutoResizingTextarea
        isError={false}
        minHeight={minHeight}
        maxHeight={maxHeight}
        placeholder={placeholder}
        value={value}
        onChange={async e => onChangeHandler(e.target.value)}
      />
    </S.DescriptionTextareaContainer>
  )
}
