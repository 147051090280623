import styled from 'styled-components'
import {
  KnowledgeBaseContainer,
  Title as KBTitle
} from '../KnowledgeBaseReplies/KnowledgeBaseReplies.style'

const AIActionsContainer = styled(KnowledgeBaseContainer)`

`

const Header = styled.div`
  display: flex;
`

const Title = styled(KBTitle)`
  display: flex;
  align-items: center;
  margin: 0 32px 0 0;
    
  & svg {
    margin-left: 8px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;

  & button {
    background-color: var(--color-button-primary);
    box-shadow: 0px 3px 9px rgba(19, 69, 186, 0.206267);
    height: 40px;
    width: 40px;
    padding: 0;
    min-width: 40px;
    border-radius: 25px;
    margin-right: 8px;
      
    &:hover {
      background-color: var(--color-button-primary);
    }
  }
`

const ButtonLabel = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: var(--color-text-primary);
`

const AIActionsTableContainer = styled.div`

`

export {
  AIActionsContainer,
  Header,
  Title,
  ButtonContainer,
  ButtonLabel,
  AIActionsTableContainer
}
