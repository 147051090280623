import React, { FC } from 'react'
import * as S from './ParametersTab.styles'
import { ParameterForm } from './ParameterForm'
import { ActionParameterType } from '../AddNewActionModal'
import { ModalActionFields } from '../../../../constants/defaultValues'

interface Props {
  parameters: ActionParameterType[]
  onChangeHandler: (type: ModalActionFields, value: string | boolean | number, index?: number) => void
  addNewParameter: () => void
  deleteParameter: (parameterIndex: number) => void
}

export const ParametersTab: FC<Props> = ({ parameters, onChangeHandler, addNewParameter, deleteParameter }) => {
  return (
    <S.ParametersTabContainer>
      <S.TabHeader>
        <S.TabHeaderTitle maxWidth={230}>Attribute</S.TabHeaderTitle>
        <S.TabHeaderTitle maxWidth={180}>Type</S.TabHeaderTitle>
        <S.TabHeaderTitle maxWidth={70}>Required</S.TabHeaderTitle>
      </S.TabHeader>
      <S.TabBody>
        {parameters &&
          parameters.map((parameter, index) => (
            <ParameterForm
              key={parameter.id}
              parameterIndex={index}
              parameter={parameter}
              onChangeHandler={onChangeHandler}
              deleteParameter={deleteParameter}
            />
          ))}
        <S.AddButton onClick={addNewParameter}>+ Add parameter</S.AddButton>
      </S.TabBody>
    </S.ParametersTabContainer>
  )
}
