import { BASE_URL } from '../../../configs'
import { alertError, alertSuccess, createJsonHeaders, logoutOnRequestOrResponseJson } from '../../../api'
import { saveActions, saveTotalElements, setIsLoadingAIActions, getCurrentPage } from '../actions/aiActions'
import { AIAction } from '../reducers/aiActionsSection'

export const AI_ACTIONS_PAGE_SIZE = 10

export const getAIActions = async (botId: number, page: number, pageSize = AI_ACTIONS_PAGE_SIZE) => {
  const response = await fetch(`${BASE_URL}/bot/${botId}/ai-actions?page=${page}&size=${pageSize}`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'GET',
  })
  try {
    const json = await logoutOnRequestOrResponseJson(response)
    setIsLoadingAIActions(false)
    saveActions(json.data.content)
    saveTotalElements(json.data.totalElements)
  } catch (e) {
    alertError('Sorry but something went wrong during AI actions fetching. Please ping support!')
  }
}

export const createAIAction = async (botId: number, aiAction: AIAction) => {
  const response = await fetch(`${BASE_URL}/bot/${botId}/ai-actions`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify(aiAction),
  })
  try {
    await logoutOnRequestOrResponseJson(response)
    alertSuccess('AI Action added successfully.')
    const currentPage = getCurrentPage()
    getAIActions(botId, currentPage)
  } catch (e) {
    const message = e.message || 'Sorry but something went wrong during AI action creation. Please ping support!'
    alertError(message)
  }
}

export const deleteAIActionById = async (botId: number, aiActionId: number) => {
  const response = await fetch(`${BASE_URL}/bot/${botId}/ai-actions/${aiActionId}`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'DELETE',
  })
  try {
    await logoutOnRequestOrResponseJson(response)
    alertSuccess('AI Action deleted successfully.')
    getAIActions(botId, 0)
  } catch (e) {
    const message = e.message || 'Sorry but something went wrong during AI action deletion. Please ping support!'
    alertError(message)
  }
}

export const updateAIAction = async (botId: number, aiAction: AIAction) => {
  const response = await fetch(`${BASE_URL}/bot/${botId}/ai-actions/${aiAction.id}`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'PUT',
    body: JSON.stringify(aiAction),
  })
  try {
    await logoutOnRequestOrResponseJson(response)
    const currentPage = getCurrentPage()
    getAIActions(botId, currentPage)
  } catch (e) {
    const message = e.message || 'Sorry but something went wrong during AI action update. Please ping support!'
    alertError(message)
  }
}
