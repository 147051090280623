import React, {MouseEventHandler} from 'react'

import HoverButton from '../../../../uiKit/buttons/HoverButton/HoverButton'

import classes from './styles.module.scss'
import JsonEditor from 'uiKit/JsonEditor/JsonEditor'

interface Props {
  addUserData: boolean
  body: string
  scrollBlock: any
  onChange(body: string): void
  onCheck: MouseEventHandler<HTMLDivElement>
}

const BodyTab: React.FC<Props> = ({ addUserData, body, scrollBlock, onChange, onCheck }) => {
  return (
    <div className={classes.container}>
      <JsonEditor onChange={onChange} initialValue={body || ''}>
        <HoverButton onChange={onCheck} title={'Add full user data'} value={addUserData} />
      </JsonEditor>
    </div>
  )
}

export default BodyTab
