import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { hasPermissionForBot } from '../../../../security.js'
import Heading from 'uiKit/texts/Heading'
import { KnowledgeBaseSubTabMap } from 'tabs/nlp/constants'

import RadioButtonGroup from './RadioButtonGroup'
import WebsiteTab from './WebsiteTab'
import TextTab from './TextTab'
import FileTab from './FileTab'
import SettingsTab from './SettingsTab'

import * as S from './KnowledgeBase.style'
import { BotType } from 'models/BotType.js'

const KnowledgeBase = props => {
  const {
    match: { params },
    activeBot,
  } = props

  const subTabFromPath = params.sourceType
  const sourceId = params.sourceId
  const validatedSubTab = subTabFromPath in KnowledgeBaseSubTabMap && subTabFromPath

  const [activeTab, setActiveTab] = useState(validatedSubTab || KnowledgeBaseSubTabMap.WEBSITE)

  const nlpSettingsEnabled = hasPermissionForBot(activeBot?.id, 'nlpSettings')

  return (
    <S.Container>
      <Heading>AI Knowledge</Heading>
      <S.SubHeader>Connect different data sources for the chatbot knowledgebase</S.SubHeader>
      <RadioButtonGroup value={activeTab} onChange={setActiveTab} />
      {activeTab === KnowledgeBaseSubTabMap.WEBSITE && <WebsiteTab />}
      {activeTab === KnowledgeBaseSubTabMap.FILE && <FileTab />}
      {activeTab === KnowledgeBaseSubTabMap.TEXT && <TextTab sourceId={sourceId} />}
      {nlpSettingsEnabled && activeTab === KnowledgeBaseSubTabMap.SETTINGS && <SettingsTab />}
    </S.Container>
  )
}

const mapStateToProps = (state: { activeBot: BotType }) => ({
  activeBot: state.activeBot,
})

export default withRouter(connect(mapStateToProps)(KnowledgeBase))
