import { alertError, createJsonHeaders, logoutOnRequestOrResponseJson } from '../../../../../api'
import { BASE_URL } from 'configs'
import { ZapierConfigs } from 'models/ZapierConfigs'

export const ZAPIER_CONFIGS_PLACEHOLDER = {
  id: null,
  webhookUrl: null,
  isEnabled: false,
  attributes: [],
}

export const fetchZapierConfigs = async (botId: number): Promise<ZapierConfigs> => {
  try {
    const response = await fetch(`${BASE_URL}/bot/${botId}/zapier`, {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'GET',
    })
    const json = await logoutOnRequestOrResponseJson(response)
    return json.error?.code === 'ERR_NOT_FOUND' ? ZAPIER_CONFIGS_PLACEHOLDER : json.data
  } catch (error) {
    alertError('Failed to fetch Zapier configs.')
  }
}

export const updateZapierConfigs = async (botId: number, zapierConfigs: ZapierConfigs): Promise<ZapierConfigs> => {
  try {
    const response = await fetch(`${BASE_URL}/bot/${botId}/zapier`, {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'PUT',
      body: JSON.stringify(zapierConfigs),
    })
    return logoutOnRequestOrResponseJson(response).then(json => json.data)
  } catch (error) {
    alertError('Failed to update Zapier configs.')
  }
}

export const sendTestWebhookNotification = async (botId: number) => {
  try {
    const response = await fetch(`${BASE_URL}/bot/${botId}/zapier/test`, {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'POST',
    })
    return logoutOnRequestOrResponseJson(response)
  } catch (error) {
    alertError('Failed to send test request.')
  }
}
