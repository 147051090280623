import React, { useEffect, useState } from 'react'
import { ClickAwayListener, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Popper from '@material-ui/core/Popper'

import Search from '../../uiKit/Search/Search'
import AttributesModal from '../../tabs/settings/components/general/AttributesModal/AttributesModal'
import AttributeItem from '../AttributeItem/AttributeItem'

import { styles } from './styles'
import { CHATBOT_TITLE, DEFAULT_TITLE, FACEBOOK_TITLE, TWILIO_TITLE, WHATSAPP_TITLE, WIDGET_TITLE } from './config'
import { CHATBOT, DEFAULT, FACEBOOK, TWILIO, WHATSAPP, WIDGET } from '../../constants/attributeTypes'
import { alertError } from '../../api'

// eslint-disable-next-line
import { useFacebookActivePagesContext } from '../../tabs/settings/components/integrations/facebook/FacebookActivePages/context'

const AttributesList = props => {
  const {
    classes,
    show,
    onClose,
    position,
    addParam,
    activeBot,
    showSearch,
    attributes,
    twilioCredentials,
    listRef,
    selectedAttribute,
    updateSelectedAttribute,
    onKeyDown,
  } = props
  const [searchValue, setSearchValue] = useState('')
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [isMatch, setIsMatch] = useState(false)
  const [isMatchAll, setIsMatchAll] = useState(false)
  const { pages } = useFacebookActivePagesContext()

  const isFacebookConnected = pages?.length > 0
  const isWhatsappConnected = activeBot?.dialog360WhatsAppConfigs?.apiKey
  const isTwilioConnected = twilioCredentials?.connected

  useEffect(() => {
    const chatbotAttributes = getAttributeList(CHATBOT, true, CHATBOT_TITLE)
    const widgetAttributes = getAttributeList(WIDGET, true, WIDGET_TITLE)
    const facebookAttributes = getAttributeList(FACEBOOK, isFacebookConnected, FACEBOOK_TITLE)
    const whatsappAttributes = getAttributeList(WHATSAPP, isWhatsappConnected, WHATSAPP_TITLE)
    const twilioAttributes = getAttributeList(TWILIO, isTwilioConnected, TWILIO_TITLE)
    const defaultAttributes = getAttributeList(DEFAULT, true, DEFAULT_TITLE)

    const optionsList = [
      ...defaultAttributes,
      ...chatbotAttributes,
      ...widgetAttributes,
      ...facebookAttributes,
      ...whatsappAttributes,
      ...twilioAttributes,
    ]

    const isMatch = !!optionsList?.filter(({ name }) => {
      return name?.includes(searchValue) && name?.includes(props.searchValue)
    })?.length

    const isMatchAll = !!attributes?.filter(({ id, name }) => {
      return (
        !optionsList.some(option => option.id === id) &&
        (props.searchValue ? props.searchValue === name : showSearch) &&
        (searchValue ? searchValue === name : !showSearch)
      )
    })?.length

    if (isMatchAll) {
      alertError('This attribute name already exists')
    }

    setIsMatchAll(isMatchAll)
    setIsMatch(isMatch)
    setOptions(optionsList)
  }, [attributes?.length, searchValue, props.searchValue])

  useEffect(() => {
    setSearchValue('')
  }, [show])

  useEffect(() => {
    updateSelectedAttribute(1)
  }, [searchValue])

  const getAttributeList = (type, connected, titleObject) => {
    const options = filterOptions(type)

    return options?.length && connected ? [titleObject, ...options] : []
  }

  const handleClose = attribute => {
    addParam(attribute.name)
  }

  const handleCreateAttribute = () => {
    const newAttribute = { name: searchValue || props.searchValue }
    handleClose(newAttribute)
  }

  const filterOptions = type => {
    return attributes
      ?.filter(attribute => attribute.type === type && attribute?.name?.includes(searchValue))
      ?.filter(attribute => attribute.type === type && attribute?.name?.includes(props.searchValue))
  }

  const handleOpenModal = () => {
    setOpen(true)
    onClose()
  }

  // Don't change the structure here. AttributesList is designed to get the selected attribute by list child node index
  return (
    <>
      {show && (attributes?.length || searchValue || props.searchValue) && (
        <Popper open={Boolean(show)} style={position} className={classes.container}>
          <ClickAwayListener onClickAway={onClose}>
            <div>
              {showSearch && (
                <Search
                  value={searchValue}
                  onChange={e => setSearchValue(e.target.value)}
                  onKeyDown={onKeyDown}
                  style={{ margin: 6 }}
                />
              )}
              <div
                className={classes.attributesContainer}
                onKeyDown={onKeyDown}
                tabIndex={'0'}
                ref={listRef}
                data-autotest={'dropdown-attributes'}>
                {isMatch &&
                  options.map((attribute, index) => (
                    <AttributeItem
                      key={index} // Cannot use id as group names don't have it
                      attribute={attribute}
                      onClick={handleClose}
                      selectedAttribute={selectedAttribute}
                      index={index}
                      onMouseEnter={updateSelectedAttribute}
                    />
                  ))}
                {!isMatch && !isMatchAll && (
                  <div className={classes.addAttibute} onClick={handleCreateAttribute}>
                    <p className={classes.optionTitle}>Create new attribute:</p>
                    <p className={classes.option}>{searchValue || props.searchValue}</p>
                  </div>
                )}
              </div>

              <div className={classes.button} onClick={handleOpenModal}>
                Manage attributes
              </div>
            </div>
          </ClickAwayListener>
        </Popper>
      )}

      <AttributesModal open={open} onClose={() => setOpen(false)} />
    </>
  )
}

AttributesList.propTypes = {
  classes: PropTypes.object,
}

const mapStateToProps = state => ({
  attributes: state.attributes,
  activeBot: state.activeBot,
  twilioCredentials: state.twilioCredentials,
})

export default withStyles(styles)(connect(mapStateToProps)(AttributesList))
