import styled from 'styled-components'

const ActionButtonsContainer = styled.div`
  display: flex;

`

const ActionButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  display: block;
  margin-right: 16px;
  
  &:last-child {
    margin-right: 0;
  }
  
  & svg {
    width: 24px;
    height: 24px;
    display: block;
  }
`

export { ActionButtonsContainer, ActionButton }
