import React, { FC } from 'react'
import Modal from '@material-ui/core/Modal'
import classes from '../../knowledgeBase/SourcesTable/styles.module.scss'
import { CrossIcon } from 'uiKit/icons/CrossIcon'
import SubmitButton from 'uiKit/buttons/SubmitButton'

interface Props {
  open: boolean
  onClose: () => void
  onDelete: (source: any) => void
}

export const DeleteActionModal: FC<Props> = ({ open, onClose, onDelete }) => {

  return (
    <Modal open={open} onClose={onClose}>
      <div className={classes.container}>
        <p className={classes.title}>
          Are you sure you want to delete selected AI action?
        </p>
        <div className={classes.closeButton} onClick={onClose}>
          <CrossIcon color={'var(--color-spun-pearl-grey)'} />
        </div>
        <div className={classes.buttonsWrap}>
          <div className={classes.deleteButton} onClick={onDelete}>
            Delete
          </div>
          <SubmitButton onClick={onClose} title="Cancel" />
        </div>
      </div>
    </Modal>
  )
}
