import React, { ReactElement } from 'react'
import Modal from '@material-ui/core/Modal'
import SubmitButton from '../buttons/SubmitButton'
import { CrossIcon } from '../icons/CrossIcon'

import classes from './styles.module.scss'

interface Props {
  open: boolean
  onClose: (e: any, hasPressedXButton?: boolean) => void
  onSave?: any
  title: string
  children: ReactElement
  titleIcon?: ReactElement
  customFooter?: ReactElement
  containerClasses?: string
  withFooter?: boolean
  hasCancelButton?: boolean
  cancelButtonTitle?: string
  saveButtonTitle?: string
}

const ModalTemplate: React.FC<Props> = ({
  open,
  onClose,
  onSave,
  title,
  children,
  customFooter,
  containerClasses,
  titleIcon,
  withFooter = true,
  hasCancelButton = true,
  cancelButtonTitle,
  saveButtonTitle
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className={`${classes.container} ${containerClasses || ''}`}>
        <div className={classes.header}>
          <p className={classes.title}>
            {title}
            {titleIcon}
          </p>
          <div className={classes.closeButton} onClick={(e) => onClose(e, true)}>
            <CrossIcon color={'var(--color-spun-pearl-grey)'} />
          </div>
        </div>
        {children}
        {withFooter && (
          <div className={classes.footer}>
            <div className={classes.buttons}>
              {hasCancelButton && <SubmitButton
                title={cancelButtonTitle || 'Cancel'}
                type='primary_with_border'
                styles={{ borderRadius: 4 }}
                onClick={onClose}
              />}
              <SubmitButton
                title={saveButtonTitle || 'Save'}
                type="primary"
                styles={{ borderRadius: 4 }}
                onClick={() => onSave && onSave()}
              />
            </div>
            {customFooter}
          </div>
        )}
      </div>
    </Modal>
  )
}

export default ModalTemplate
