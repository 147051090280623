import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import Reducers from 'reducers'
import { SparklesIcon } from 'uiKit/icons/SparklesIcon'
import Button from '@material-ui/core/Button'
import { PlusIcon } from 'uiKit/icons/Icons'
import LoaderScreen from 'uiKit/loaders/loaderScreen'
import * as S from './AIActions.styles'
import NoChartData from 'uiKit/NoChartData'
import Pagination from 'uiKit/table/Pagination'
import AIActionsTable from './AIActionsTable'
import { updateCurrentPage } from '../../actions/aiActions'
import { getAIActions, deleteAIActionById, AI_ACTIONS_PAGE_SIZE } from '../../api/aiActions'
import { DeleteActionModal } from './ActionModals/DeleteActionModal'
import AddNewActionModal from './ActionModals/AddNewActionModal'
import { AIAction } from '../../reducers/aiActionsSection'

const AIActions = ({ activeBot }) => {
  const {
    aiActionsSection: { aiActions, isLoadingAIActions, currentPage, totalElements },
  } = useSelector((state: ReturnType<typeof Reducers>) => state)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [isOpenActionModal, setIsOpenActionModal] = useState(false)
  const [selectedAction, setSelectedAction] = useState<AIAction>(null)

  useEffect(() => {
    getAIActions(activeBot.id, 0)
  }, [])

  const onChangePageHandler = (e, page) => {
    updateCurrentPage(page)
    getAIActions(activeBot.id, page)
  }

  const editActionHandler = (action: AIAction) => {
    setSelectedAction(action)
    setIsOpenActionModal(true)
  }

  const deleteActionHandler = () => {
    setIsOpenDeleteModal(false)
    deleteAIActionById(activeBot.id, selectedAction.id)
  }

  return (
    <S.AIActionsContainer>
      <S.Header>
        <S.Title>
          AI Actions
          <SparklesIcon />
        </S.Title>
        <S.ButtonContainer>
          <Button onClick={() => setIsOpenActionModal(true)} variant="raised">
            <PlusIcon height="13px" width="13px" color="var(--color-text-on-secondary)" />
          </Button>
          <S.ButtonLabel>Add new action</S.ButtonLabel>
        </S.ButtonContainer>
      </S.Header>
      {isLoadingAIActions ? (
        <LoaderScreen />
      ) : !aiActions.length ? (
        <NoChartData text="You have no AI Actions yet. Start by pressing the button -  Add new action" />
      ) : (
        <S.AIActionsTableContainer>
          <AIActionsTable
            setIsOpenDeleteModal={setIsOpenDeleteModal}
            setSelectedAction={setSelectedAction}
            editActionHandler={editActionHandler}
          />
          <Pagination
            page={currentPage}
            rowsPerPage={AI_ACTIONS_PAGE_SIZE}
            count={totalElements}
            onChangePage={onChangePageHandler}
          />
        </S.AIActionsTableContainer>
      )}

      {isOpenDeleteModal && (
        <DeleteActionModal open={true} onClose={() => setIsOpenDeleteModal(false)} onDelete={deleteActionHandler} />
      )}
      {isOpenActionModal && (
        <AddNewActionModal
          isOpenActionModal={isOpenActionModal}
          onClose={() => setIsOpenActionModal(false)}
          selectedAction={selectedAction}
          setSelectedAction={setSelectedAction}
        />
      )}
    </S.AIActionsContainer>
  )
}

const mapStateToProps = (state: { activeBot: any }) => ({
  activeBot: state.activeBot,
})

export default connect(mapStateToProps)(AIActions)