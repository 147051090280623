import { alertError, createJsonHeaders, logoutOnRequestOrResponseJson } from '../../../api/'
import { BASE_URL } from '../../../configs'
import { SourceTypes } from '../constants'

export type KnowledgeBaseReplySource = {
  sourceTitle: string
  sourceType: SourceTypes
  replyCount: number
}

export const getKnowledgeBaseReplySources = async (
  botId: number,
  startDate: string,
  endDate: string,
): Promise<KnowledgeBaseReplySource[]> => {
  try {
    const response = await fetch(BASE_URL + `/bot/${botId}/knowledge/replies/sources?from=${startDate}&to=${endDate}`, {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'GET',
    })
    const json = await logoutOnRequestOrResponseJson(response)
    return json.data
  } catch (error) {
    alertError('Failed to fetch sources used during AI Replies. Please ping support.')
  }
}
