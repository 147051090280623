import { ARROW_UP_KEY } from '../../constants/keyCodes'

export const updateAttributeListScroll = (attributesListElement, direction, selectedAttributeIndex) => {
  let selectedChildNodeIndex
  const childNodes = attributesListElement?.childNodes
  const directionStep = direction === ARROW_UP_KEY ? -1 : 1
  const childNodesLength = childNodes?.length - 1

  //change index of highlighted item
  if (childNodes[selectedAttributeIndex + directionStep]) {
    selectedChildNodeIndex = selectedAttributeIndex + directionStep
  } else {
    selectedChildNodeIndex = direction === ARROW_UP_KEY ? childNodesLength : 1
  }

  //make one step ahead when childNode is not item
  if (childNodes[selectedChildNodeIndex].nodeName !== 'DIV') {
    selectedChildNodeIndex =
      selectedChildNodeIndex + directionStep < 1 ? childNodesLength : selectedChildNodeIndex + directionStep
  }

  const selectedChildNode = childNodes[selectedChildNodeIndex]

  selectedChildNode.scrollIntoView({
    behavior: 'auto',
    block: 'center',
    inline: 'center',
  })

  return selectedChildNodeIndex
}
