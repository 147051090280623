import styled from 'styled-components'

const EditorContainer = styled.div`
  position: relative;

  &:hover > div {
    display: block;
  }
`

const EditorHoverButtonsWrap = styled.div`
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10;
`

const FormatJsonHint = styled.p`
  font-size: 12px;
  margin: 0;
  margin-top: 5px;
`

export { EditorContainer, EditorHoverButtonsWrap, FormatJsonHint }
