import styled from 'styled-components'
import * as S from '../../UserSaysTab/UserSaysTab.style'
import { DescriptionLabel, DescriptionLabelContainer } from '../../DescriptionTextarea/DescriptionTextarea.styles'

const InputWrapper = styled.div`

`

const InputContainer = styled(S.InputHolder)`
  margin: 0;
  border-color: var(--color-platinum);
`

const InputLabelContainer = styled(DescriptionLabelContainer)``

const InputLabel = styled(DescriptionLabel)``

const Input = styled(S.Input)``

const EnterButton = styled(S.Enter)`

`

const Alert = styled(S.Alert)`

`

const ParameterValuesWrapper = styled(S.UserSaysWrapper)`

`

export {
  InputWrapper,
  InputLabelContainer,
  InputContainer,
  InputLabel,
  Input,
  EnterButton,
  Alert,
  ParameterValuesWrapper
}
